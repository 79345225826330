import React, { useState } from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';

import { CBInput,CBButton } from './core/components/cbd'
import {
  validateEmail,
  signupUser,
  signinUser,
  INVALID_EMAIL,
} from '../utils/auth'
import { useFormFields } from '../utils/forms'
import { navigate } from '@reach/router'

import Amplify from 'aws-amplify'
import apiConfig from '../utils/apiConfig'

Amplify.configure(apiConfig)

const CtaForm1 = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState(props.email)
  const [errorMessage, setErrorMessage] = useState(null)

  const [referral, setReferral] = useState(props.referral)
  async function requestSignup(e) {
    e.preventDefault()
    if (!validateEmail(email)) {
      setErrorMessage(INVALID_EMAIL)
      return
    }

    await signupUser(email)
    await requestLogin()
    navigate(`/login?requested=true&fromCta=true${referral ? `&referral=${referral}` :''}`,)
  }

  async function requestLogin() {
    if (!validateEmail(email)) {
      setErrorMessage(INVALID_EMAIL)
      return
    }

    setIsLoading(true)
    setErrorMessage(null)

    try {
      await signinUser(email)
    } catch (e) {
      setErrorMessage(e.message)
    }
    setIsLoading(false)
  }

  return (
    <div clasName="flex flex-col">
    <form style={{marginTop: '0px'}} onSubmit={requestSignup}>
      
        <CBInput
          size={props.size || 'large'}
          hint="email@youremail.com"
          type="email"
          id="email"
          value={email}
          autoFocus={props.autofocus}
          disabled={isLoading}
          onChange={e=> setEmail(e)}
        />
      
        <CBButton
          
          onClick={requestSignup}
          htmlType="submit"
          block
          type={`primary${props.type=="blue" ? '':'-burnt'}`}
          size={props.size || 'large'}
          loading={isLoading}
          style={{fontWeight: 'bold'}}
        >
          Try CalendarBridge For Free
        </CBButton>
        
    </form>
    {errorMessage && <div className="w-48 text-center text-red-700">{errorMessage}</div>}
      </div>
  )
}

const styles = {
  emailField: {
    padding: 0,
    textAlign: 'center',
    fontWeight: 'bold',
  },
}

export default CtaForm1
