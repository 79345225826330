import { colorOutlook, colorGoogle, colorExchange, colorApple } from './colors'
import { faGoogle,faApple,  } from '@fortawesome/free-brands-svg-icons'

const prefix = 'fab'
const iconName = 'youtube'
const width = 576
const height = 512
const ligatures = []
const unicode = 'f222'
const svgPathData =
  'm340.89358,62.51901c0.78289,4.88509 0.98338,358.10097 0.23869,365.80891c-60.56875,-14.29565 -121.0484,-28.57857 -181.77627,-42.91241l0,-248.99002c-31.26458,-12.1093 -62.41777,-24.16767 -93.55823,-36.21332c-0.2005,0.11139 -0.37235,0.17185 -0.53465,0.28642c-0.13685,0.09866 -0.31188,0.24823 -0.32461,0.38508c-0.09866,0.93246 -0.22277,1.86493 -0.22277,2.81012c-0.01273,100.6074 -0.01273,201.22752 -0.01273,301.58669c1.39392,1.70262 3.1952,1.91585 4.76097,2.48551c89.01048,33.03085 178.04642,66.02669 267.06644,99.05435c3.08381,1.14569 5.8812,1.7058 9.29917,0.70969c48.84454,-14.04742 97.73681,-27.93253 146.61635,-41.86856c0.70969,-0.21323 1.41938,-0.46146 2.42504,-0.78289l0,-424.05128c-14.03469,-3.8667 -27.94526,-7.7334 -41.86856,-11.53645c-35.44316,-9.68425 -70.90859,-19.29531 -106.32629,-29.05276c-3.46889,-0.95792 -6.50178,-0.88473 -9.91021,0.35962c-87.55291,32.06019 -175.14719,64.02173 -262.71283,95.996c-1.72808,0.63331 -3.42115,1.343 -4.87554,2.87059c90.57625,-12.31934 181.15251,-24.62595 271.71603,-36.94528z'
const faOutlook = {
  icon: [width, height, ligatures, unicode, svgPathData],
  prefix,
  iconName,
  width,
  height,
  ligatures,
  unicode,
  svgPathData,
}

const accountIconMap = {
  ms: {
    icon: faOutlook,
    color: colorOutlook,
  },
  google: {
    icon: faGoogle,
    color: colorGoogle,
  },
  caldav: {
    icon: faApple,
    color: colorApple,
  },
  ics: {
    icon: faApple,
    color: colorApple,
  },
  ews: {
    icon: faOutlook,
    color: colorExchange,
  },
}

export { faOutlook, accountIconMap, faApple }
