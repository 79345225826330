import React from 'react'
import { CloseOutlined, Loading3QuartersOutlined } from '@ant-design/icons'
import SelectOn from '../../../images/svg/selected-yes.svg'
import SelectOff from '../../../images/svg/selected-no.svg'
import GoogleLogo from '../../../images/svg/google-logo.svg'
import Trash from '../../../images/delete.png'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import CtaForm1 from '../../ctaform1'
import {
  Select,
  Col,
  Radio,
  Spin,
  message,
  Empty,
  Result,
  Alert,
  Layout,
  Button,
} from 'antd'
import {
  CBInput,
  CBButton,
  SubmitButton,
  CBRadioGroup,
  CBListItem,
  CBList,
  CBCalendar,
  CBTextArea,
} from '../../core/components/cbd'
import CBCard from '../../core/components/CBCard'
import ActionButton from '../../core/components/ActionButton'
import LongButton from '../../core/components/LongButton'
import moment from 'moment'
import { API, Auth } from 'aws-amplify'
import momentTz from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faOutlook } from '../../../utils/customIcons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import {
  faArrowLeft,
  faDoorClosed,
  faHistory,
} from '@fortawesome/free-solid-svg-icons'
import LogoMono from '../../../images/svg/cb_log_2024_orange_white.svg'
import { loadAllData } from '../../../utils/fetchData'
import { colorOutlook, colorGoogle } from '../../../utils/colors'
import QueryString from 'query-string'
import { validateEmail } from '../../../utils/auth'

import Carat from '../../../images/arrow-right-chevron.png'
import SEO from '../../../components/SEO'

// const DEBUG_ICON_KEY = "e9057eb8-fce5-4352-acde-57b0190a6b5b/iconImages/Screenshot 2021-11-02 132206.png";
// const DEBUG_AVATAR_KEY = "e9057eb8-fce5-4352-acde-57b0190a6b5b/avatarImages/test.jpg";
const { Content, Footer } = Layout
Array.prototype.rotate = (function () {
  var unshift = Array.prototype.unshift,
    splice = Array.prototype.splice

  return function (count) {
    var len = this.length >>> 0,
      count = count >> 0

    unshift.apply(this, splice.call(this, count % len, len))
    return this
  }
})()

const redirectToService = async (redirectUri) => {
  window.location.href = redirectUri
}

const GroupAuth = (id, groupid, groupmemberid, accounts = []) => {
  const doAuth = (type) => {
    var state = window.location.origin + '/login?redirect=/book/' + id
    if (groupid != null) {
      state =
        window.location.origin +
        '/login?redirect=/book/' +
        id +
        '/' +
        groupid +
        '/' +
        groupmemberid
    }
    if (type == 'cb') {
      redirectToService(state)
    } else if (type == 'ms') {
      redirectToService(
        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?prompt=login&client_id=${
          process.env.GATSBY_OAUTH_O365_CLIENT
        }&response_type=code&scope=offline_access+Calendars.ReadWrite+User.Read&state=${
          window.location.origin + '/book/' + this.props.id
        }&redirect_uri=${
          window.location.origin + process.env.GATSBY_OAUTH_O365_URL_BOOK
        }`
      )
    } else {
      redirectToService(
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
          process.env.GATSBY_OAUTH_GOOGLE_CLIENT
        }&redirect_uri=${
          window.location.origin + process.env.GATSBY_OAUTH_GOOGLE_URL_BOOK
        }&response_type=code&prompt=login&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&access_type=offline&state=${
          window.location.origin + '/book/' + this.props.id
        }`
      )
    }
  }

  return (
    <div className="flex justify-center mb-12 mt-12">
      <div className="flex flex-col justify-center mt-2">
        <Col>
          <center>
            <h4>Automatically Schedule Times</h4>
            <div className="flex gap-4 flex-row justify-center">
              <a>
                <GoogleLogo
                  width="28"
                  height="28"
                  onClick={(e) => doAuth('google')}
                />
              </a>
              <a>
                <FontAwesomeIcon
                  icon={faOutlook}
                  size="2x"
                  color={colorOutlook}
                  onClick={(e) => doAuth('ms')}
                />
              </a>
            </div>
          </center>
        </Col>
        <center>
          <small style={{ color: 'rgba(0,0,0,.65)' }}>
            Already have a CalendarBridge account?{' '}
            <a onClick={(e) => doAuth('cb')}>log in</a>
          </small>
        </center>
      </div>
    </div>
  )
}

class ScheduleBookingPage extends React.Component {
  state = {
    error: null,
    page: 0,
    addGuests: false,
    lastPage: 1,
    dayRange: '180',
    already_selected_items: {},
    selected_items: {},
    declineMe: false,
    range: [
      momentTz().tz(momentTz.tz.guess()),
      momentTz().tz(momentTz.tz.guess()).add(180, 'days'),
    ],
    submitting: false,
    timezone: momentTz.tz.guess(), //Intl.DateTimeFormat().resolvedOptions().timeZone,
    booking: {
      subject: '',
      body: '',
      email: '',
      name: '',
      guests: [],
      meetingType: 'zoom',
      startTime: null,
      endTime: null,
      body: '',
      // startTimezone: null,
      // endTimezone: null,
    },
    bookingValidation: {
      subject: true,
      body: true,
      email: true,
      name: true,
      guests: true,
    },
    group: null,
    schedule: null,
    events: [],
    excludedDays: {},
    freeTimes: [],
    firstFreeDate: null,
    currentDate: null,
    selectedIncrement: 0,
    allCalendars: [],
    accounts: [],
    guests: null,
    bookerCalendarsSelected: [],
    showBookerCalendarSelector: true,
    guestSelected: null,
    authed: false,
    questions: {},
    iconImage: null,
    avatarImage: null,
  }

  componentDidMount() {
    this.loadLoggedInUser()
    const query = new URLSearchParams(this.props.location.search)
    this.loadSchedule(0)
  }
  loadAuthedUser = async () => {
    const authed = localStorage.getItem('cal_auth_account')
    const email = localStorage.getItem('cal_auth_email')
    const type = localStorage.getItem('cal_auth_account_type')
    if (authed) {
      try {
        var calendars = await API.post(
          'group-bookings',
          '/group-bookings?calendars=true',
          {
            body: {
              email: email,
              calendarAccountId: authed,
              type: type,
            },
          }
        )

        const accounts = [calendars.account]
        const allCalendars = accounts
          .reduce((all, a) => [...all, ...a.calendars], [])
          .filter((calendar) => {
            if (
              calendar.name == 'Birthdays' ||
              calendar.name == 'United States holidays' ||
              calendar.summary == 'Birthdays' ||
              calendar.summary == 'Holidays in United States'
            ) {
              return false
            } else {
              return true
            }
          })

        for (const [acctIdx, acct] of accounts.entries()) {
          for (const [calIdx, cal] of acct.calendars.entries()) {
            accounts[acctIdx].calendars[calIdx].calendarAccountId =
              acct.calendarAccountId
            accounts[acctIdx].calendars[calIdx].calendarAccountType =
              acct.calendarAccountType
          }
        }
        this.setState({
          accounts: accounts,
          allCalendars: allCalendars,
        })
        this.changeBookingValue({
          email: calendars.account.email,
        })
      } catch (error) {
        console.log(error)
      }
    } else {
    }
  }

  loadLoggedInUser = async () => {
    try {
      const session = await Auth.currentSession()

      const { accounts, license } = await loadAllData(session)

      var allCalendars = accounts
        .reduce((all, a) => [...all, ...a.calendars], [])
        .filter((calendar) => {
          if (
            calendar.name == 'Birthdays' ||
            calendar.name == 'United States holidays' ||
            calendar.summary == 'Birthdays' ||
            calendar.summary == 'Holidays in United States'
          ) {
            return false
          } else {
            return true
          }
        })

      this.setState(
        {
          allCalendars: allCalendars,
          bookerCalendarsSelected: [],
          accounts: accounts,
          authed: true,
          showBookerCalendarSelector: true,
          loggedInUserID: license.userId,
        },
        () => {
          // console.log(this.state.authed)
          this.changeBookingValue({
            email:
              this.state.schedule &&
              this.state.schedule.ownerId != license.userId
                ? license.email
                : '',
          })
          if (this.state.schedule && this.state.loggedInUserID) {
            const query = new URLSearchParams(this.props.location.search)
            var isDebug = query.get('debug')
            if (isDebug == 'true') {
              this.loadSchedule(0)
            }
          }
        }
      )
    } catch (ex) {
      this.loadAuthedUser()
    }
  }

  doAuth = (type) => {
    var state =
      window.location.origin + '/login?redirect=/book/' + this.props.id
    if (this.props.groupid != null) {
      state =
        window.location.origin +
        '/login?redirect=/book/' +
        this.props.id +
        '/' +
        this.props.groupid +
        '/' +
        this.props.groupmemberid
    }
    if (type == 'cb') {
      redirectToService(state)
    } else if (type == 'ms') {
      redirectToService(
        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?prompt=login&client_id=${
          process.env.GATSBY_OAUTH_O365_CLIENT
        }&response_type=code&scope=offline_access+Calendars.ReadWrite+User.Read&state=${
          window.location.origin + '/book/' + this.props.id
        }&redirect_uri=${
          window.location.origin + process.env.GATSBY_OAUTH_O365_URL_BOOK
        }`
      )
    } else {
      redirectToService(
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=${
          process.env.GATSBY_OAUTH_GOOGLE_CLIENT
        }&redirect_uri=${
          window.location.origin + process.env.GATSBY_OAUTH_GOOGLE_URL_BOOK
        }&response_type=code&prompt=login&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&access_type=offline&state=${
          window.location.origin + '/book/' + this.props.id
        }`
      )
    }
  }

  submitChoices = async (times) => {
    var calendars = []
    if (this.state.bookerCalendarsSelected.length) {
      calendars = this.state.bookerCalendarsSelected.map((e) => {
        var calendar = this.state.allCalendars[parseInt(e)]
        return {
          accountId: calendar.calendarAccountId,
          calendarId: calendar.id,
          calendarAccountType: calendar.calendarAccountType,
        }
      })
    }

    // console.log(this.state.selected_items)
    var choices = Object.values(this.state.selected_items).map((e) => {
      return {
        start: e.start,
        end: e.end,
      }
    })

    this.setState({ submitting: true })

    // console.log(choices)
    try {
      var choice = await API.post(
        'group-bookings',
        '/group-bookings?groupId=' +
          this.props.groupid +
          '&groupMemberId=' +
          this.props.groupmemberid +
          '&groupUpdate=true',
        {
          body: {
            groupId: this.props.groupid,
            groupMemberId: this.props.groupmemberid,
            choices: times ? choices : [],
            calendars: !times ? calendars : [],
          },
        }
      )

      // var add = ''
      // if (this.state.declineMe || this.state.selected_items.length == 0) {
      //   add = '&no_invite=true'
      // }
      // window.location = '/book/complete?group=true' + add
      this.setState({
        page: 2,
      })
    } catch (ex) {
      this.setState({ submitting: false })

      console.log(ex)
    }
  }

  addUserToGroup = async (email) => {
    this.setState({ submitting: true })
    try {
      var choice = await API.post(
        'group-bookings',
        '/group-bookings?groupId=' +
          this.props.groupid +
          '&addUser=' +
          'true' +
          '&groupUpdate=true',
        {
          body: {
            groupId: this.props.groupid,
            email: email,
          },
        }
      )

      //redirect to added user
      window.location =
        '/book/' +
        this.props.id +
        '/' +
        this.props.groupid +
        '/' +
        choice.message
    } catch (ex) {
      this.setState({
        groupError: "Sorry, we don't see that e-mail address on this meeting",
      })
    } finally {
      this.setState({ submitting: false })
    }
  }

  loadSchedule = async (relativeMonth) => {
    try {
      //selected calendars
      this.setState({loading:true})
      var calendars = []
      if (this.state.bookerCalendarsSelected.length) {
        calendars = this.state.bookerCalendarsSelected.map((e) => {
          var calendar = this.state.allCalendars[parseInt(e)]
          return {
            accountId: calendar.calendarAccountId,
            calendarId: calendar.id,
            calendarAccountType: calendar.calendarAccountType,
          }
        })
      }
      if (this.state.guests) {
        var guestCalendars = this.state.guestSelected.map((e) => {
          var calendar = this.state.guests[parseInt(e)]
          return {
            accountId: calendar.calendarAccountId,
            calendarId: calendar.calendarId,
            calendarAccountType: calendar.type,
          }
        })

        calendars.push.apply(calendars, guestCalendars)
      }
      var scheduleId = this.props.id
      if (this.props.alias) {
        scheduleId = `${this.props.id}|${this.props.alias}`
      }
      var params = ''
      if (this.props.groupid) {
        params =
          '?groupId=' +
          this.props.groupid +
          '&groupMemberId=' +
          this.props.groupmemberid
      }

      var incremental = -1
      const query = new URLSearchParams(this.props.location.search)
      var isDebug = query.get('debug')
      var test = query.get('meeting_length')
      if (test != undefined) {
        incremental = parseInt(test)
      }
      if (isDebug != undefined) {
        if (this.state.schedule) {
          isDebug = this.state.schedule.ownerId
        } else {
          isDebug = 'false'
        }
      } else {
        isDebug = 'false'
      }

      const bookings = await API.post(
        'group-bookings',
        '/group-bookings' + params,
        {
          body: {
            schedules: [scheduleId],
            limit: 1,
            offset: relativeMonth,
            additional: calendars,
            bookerTimezone: this.state.timezone,
            debug: '' + isDebug,
            increment:
              this.state.selectedIncrement == 0 && this.state.schedule == null
                ? incremental
                : parseInt(
                    this.state.schedule.meetingIncrement[
                      this.state.selectedIncrement
                    ]
                  ),
          },
        }
      )

      var slots = bookings.slots

      var freeTimes = slots.slots
      var excludedDays = slots.excludedDays
      var firstFreeDate = moment()

      if (slots.firstFreeTime) {
        firstFreeDate = momentTz.tz(
          slots.firstFreeTime.start,
          slots.firstFreeTime.startTz
        )
      }
      var currentDate = this.state.currentDate
      if (!currentDate) {
        currentDate = firstFreeDate
      }

      var dayRange = bookings.schedules[0].dayRange
      var range = [
        momentTz().tz(this.state.timezone),
        momentTz().tz(this.state.timezone).add(parseInt(dayRange), 'days'),
      ]

      // if(this.state.booking.body == null || this.state.booking.body == "") {
      //   if( bookings.schedules[0].questions != null &&  bookings.schedules[0].questions.length > 0) {
      //     console.log("QUESTIONS")
      //     var bod = ''
      //     bookings.schedules[0].questions.forEach((e)=>{
      //       bod += e + '\n\n'
      //     })
      //     this.changeBookingValue({
      //       "body": bod
      //     });
      //   } else {
      //     console.log("NO QUESTIONS")
      //   }
      // } else {
      //   console.log(this.state.booking.body)
      //   console.log("ALREADY SET BODY")
      // }

      if (this.props.groupid != null) {
        range = [moment(bookings.startRange), moment(bookings.endRange)]
      }

      if (
        bookings.schedules[0].calendarId == 'group' &&
        (this.state.guestSelected == null ||
          this.state.guestSelected.length == 0)
      ) {
        freeTimes = []
      }

      // if (
      //   this.state.schedule &&
      //   this.state.events.length != 0 &&
      //   this.state.guests != null
      // ) {
      //   // Lazy load other months
      //   this.setState({
      //     freeTimes,
      //   })
      //   return
      // }
      if (this.props.groupid) {
        var selected = {}
        var userSlots = {}

        var voteOnSlots = []
        var choices = bookings.selectedSlots.map((e) => {
          return moment(e.start)
            .tz(this.state.timezone)
            .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        })

        if (choices.length > 0) {
          var unique = {}

          voteOnSlots = bookings.selectedSlots
            .map((e) => {
              var start = moment(e.start)
                .tz(this.state.timezone)
                .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
              var end = moment(e.end)
                .tz(this.state.timezone)
                .format('YYYY-MM-DDTHH:mm:ss.SSSZ')

              var item = {
                key: start,
                event: {
                  start,
                  end,
                  disabled: e.endTz == 'disabled',
                },
                start: start,
                end: end,
              }
              return item
            })
            .filter((item) => {
              if (unique[item.start] == null) {
                unique[item.start] = item
                return true
              } else {
                return false
              }
            })
        }

        var userChoices = bookings.userSlots.map((e) => {
          var start = moment(e.start)
            .tz(this.state.timezone)
            .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
          var end = moment(e.end)
            .tz(this.state.timezone)
            .format('YYYY-MM-DDTHH:mm:ss.SSSZ')

          var item = {
            key: start,
            event: {
              start,
              end,
            },
            start: start,
            end: end,
          }
          return item
        })

        userChoices.forEach((e) => {
          userSlots[e.key] = e
        })

        choices.forEach((e) => {
          selected[e] = selected[e] ? selected[e] + 1 : 1
        })

        if (voteOnSlots.length == 0) {
          // this.setState({
          //   declineMe: true,

          // })
          this.submitChoices(true)
        } else {
          this.setState({
            already_selected_items: { ...selected },
            selected_items: { ...userSlots },
            vote_on_items: voteOnSlots,
            group: bookings.groupSchedule,
          })
        }
      }
      var incrementSelected = this.state.selectedIncrement

      if (
        relativeMonth == 0 &&
        this.state.schedule == null &&
        bookings.schedules[0].defaultTime != -1
      ) {
        incrementSelected = bookings.schedules[0].meetingIncrement.indexOf(
          '' + bookings.schedules[0].defaultTime
        )
      }

      if (
        relativeMonth == 0 &&
        this.state.schedule == null &&
        incremental != -1
      ) {
        incrementSelected = bookings.schedules[0].meetingIncrement.indexOf(
          '' + incremental
        )
        if (incrementSelected == -1) {
          incrementSelected = 0
        }
      }

      this.setState({
        loading:false,
        schedule: bookings.schedules[0],
        freeTimes,
        excludedDays,
        firstFreeDate,
        currentDate,
        dayRange,
        range,
        selectedIncrement: incrementSelected,
        debug: isDebug,
        sourceItems: bookings.sourceItems,
      })

      var loadFromParams = false
      if (bookings.schedules[0].choices) {
        var selected = null
        if (this.state.guestSelected == null) {
          selected = []
          let params = QueryString.parse(window.location.search)
          if (params['select']) {
            var thing = params['select'].split(',')

            for (var itemIdx in thing) {
              var item = thing[itemIdx]

              var index = 0
              for (var guestIdx in bookings.schedules[0].choices) {
                var guest = bookings.schedules[0].choices[guestIdx]

                if (guest.name == item || guest.email == item) {
                  loadFromParams = true
                  selected.push(`${index}`)
                }
                index++
              }
            }
          }
        } else {
          selected = this.state.guestSelected
        }

        this.setState(
          {
            guests: bookings.schedules[0].choices,
            guestSelected: selected,
          },
          () => {
            if (loadFromParams && this.state.guestSelected) {
              this.loadSchedule(0)
            }
          }
        )
      }
    } catch (ex) {
      console.log(ex)
      if (ex.message.includes('501')) {
        this.setState({
          loading: false,
          error: 'cancelled',
        })
      } else {
        this.setState({
          loading: false,
          error: 'error',
        })
      }
    }
  }

  changeMeetingIncrement = (index) => {
    this.setState({ selectedIncrement: index }, () => {
      const selMonth =
        this.state.currentDate.month() + this.state.currentDate.year() * 12
      const currMonth = moment().month() + moment().year() * 12
      this.loadSchedule(selMonth - currMonth)
    })
  }

  validateBooking = () => {
    const { booking, bookingValidation } = this.state
    const subjectValid = true // we literally don't care
    const emailValid =
      booking.email.length && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(booking.email)
    const nameValid = booking.name.length
    this.setState({
      bookingValidation: {
        ...bookingValidation,
        subject: subjectValid,
        email: emailValid,
        name: nameValid,
      },
    })

    return subjectValid && emailValid && nameValid
  }

  submitBooking = async (e) => {
    e.preventDefault()
    if (!this.validateBooking()) {
      return
    }

    var calendars = []
    if (this.state.bookerCalendarsSelected.length) {
      calendars = this.state.bookerCalendarsSelected.map((e) => {
        var calendar = this.state.allCalendars[parseInt(e)]
        return {
          accountId: calendar.calendarAccountId,
          calendarId: calendar.id,
          calendarAccountType: calendar.calendarAccountType,
        }
      })
    }
    if (this.state.guests) {
      var guestCalendars = this.state.guestSelected.map((e) => {
        var calendar = this.state.guests[parseInt(e)]
        return {
          accountId: calendar.calendarAccountId,
          calendarId: calendar.calendarId,
          calendarAccountType: calendar.type,
        }
      })

      calendars.push.apply(calendars, guestCalendars)
    }

    var { calendarAccountId, calendarId, ownerEmail } = this.state.schedule
    if (this.state.schedule.calendarId == 'group') {
      //get first selected guest
      var idx = parseInt(this.state.guestSelected[0])
      if (this.state.guests[idx] != null) {
        calendarAccountId = this.state.guests[idx].calendarAccountId
        calendarId = this.state.guests[idx].calendarId
        ownerEmail = this.state.guests[idx].email
      }
    }
    this.setState({ submitting: true })

    var scheduleId = this.props.id
    if (this.props.alias) {
      scheduleId = `${this.props.id}|${this.props.alias}`
    }
    var body = {
      id: scheduleId,
      sourceAccountId: calendarAccountId,
      sourceCalendarId: calendarId,
      startTimezone: this.state.timezone,
      endTimezone: this.state.timezone,
      ownerEmail: ownerEmail,
      calendars,
      ...this.state.booking,
    }
    var append = ''
    Object.keys(this.state.questions).forEach((e) => {
      append +=
        '\n' +
        this.state.schedule.questions[e] +
        ':\n' +
        this.state.questions[e] +
        '\n'
    })
    body.body += append
    try {
      var result = await API.post('bookings', '/bookings', {
        body,
      })

      this.nextPage()
      // this.setState({
      //   resultBody: result.scheduleResult.body,
      // })
      // if (this.state.authed) {
      //   navigate('/book/complete?authed=true')
      // } else {
      //   navigate('/book/complete?emailFill=' + this.state.booking.email)
      // }
    } catch (err) {
      message.error(err.response.data.message)
      this.loadSchedule(0)
      this.prevPage()
    }
    this.setState({ submitting: false })
  }

  nextPage = () => {
    const { page, lastPage } = this.state
    this.setState({
      page: this.state.page + 1,
    })
  }

  prevPage = () => {
    this.setState({
      page: this.state.page - 1,
    })
  }

  changePage = (page) => {
    this.setState({ page })
  }

  changeBookingValue = (map) => {
    this.setState({
      booking: {
        ...this.state.booking,
        ...map,
      },
    })
  }

  changeTimezone = (tz) => {
    this.setState({ timezone: tz, events: [], freeTimes: [] }, () => {
      const selMonth =
        this.state.currentDate.month() + this.state.currentDate.year() * 12
      const currMonth = moment().month() + moment().year() * 12
      this.loadSchedule(selMonth - currMonth)
    })

    //this.changeMeetingIncrement(this.state.selectedIncrement)
  }

  selectSourceCalendar = async (cal) => {
    this.setState(
      {
        bookerCalendarsSelected: cal,
        events: [],
        freeTimes: [], //reset events
      },
      () => {
        const selMonth =
          this.state.currentDate.month() + this.state.currentDate.year() * 12
        const currMonth = moment().month() + moment().year() * 12
        this.loadSchedule(selMonth - currMonth)
      }
    )
  }

  selectAttendee = async (guests) => {
    this.setState(
      {
        guestSelected: guests,
        events: [],
        freeTimes: [], //reset events
      },
      () => {
        const selMonth =
          this.state.currentDate.month() + this.state.currentDate.year() * 12
        const currMonth = moment().month() + moment().year() * 12
        this.loadSchedule(selMonth - currMonth)
      }
    )

    this.changeBookingValue({
      guests: guests.map((e) => this.state.guests[parseInt(e)].email),
    })
  }

  toggleShowBookerCalendarSelector = () => {
    this.setState({
      showBookerCalendarSelector: true,
    })
  }

  shouldDisableDate = (date) => {
    return this.state.excludedDays[date.format('YYYY-MM-DD')]
  }

  renderSelected = () => {
    return (
      <div style={{ marginTop: 8, marginBottom: 8 }}>
        <div className="text-lg font-manrope">Select Times</div>
        {this.state.selected_items != null && (
          <>
            {Object.values(this.state.selected_items).map((item) => {
              return this.FreeButton(item.event)
            })}
          </>
        )}
        <div>
          {Object.keys(this.state.selected_items).length > 0 && (
            <SubmitButton
              loading={this.state.submitting}
              style={{ marginBottom: 16 }}
              onClick={(e) => {
                this.submitChoices(true)
              }}
            >
              Submit Time
              {Object.keys(this.state.selected_items).length > 1 ? 's' : ''}
            </SubmitButton>
          )}
          {this.state.selected_items == null ||
            (Object.keys(this.state.selected_items).length == 0 && (
              <div className="mb-4">
                <div className="mt-8 mb-8 text-lg">Or</div>
                <CBButton
                  loading={this.state.submitting}
                  icon={<CloseOutlined />}
                  style={{ marginBottom: 16 }}
                  onClick={(e) => {
                    this.submitChoices(true)
                  }}
                >
                  Decline Meeting
                </CBButton>
              </div>
            ))}
        </div>
      </div>
    )
  }
  RenderGreeting = () => {
    return (
      <>
        {this.state.group == null && this.state.schedule.greeting == '' && (
          <div className="flex flex-row space-x-2 lg:space-x-4 pt-4 pb-4 items-center sm:pl-2 sm:pr-2">
            <div className="flex-1 font-manrope text-lg text-center mt-4">
              Find a time to meet{' '}
              {this.state.schedule && this.state.schedule.ownerName}
            </div>
          </div>
        )}

        {this.state.group == null && this.state.schedule.greeting != '' && (
          <div className="flex flex-row space-x-2 lg:space-x-4 pt-4 pb-4 items-center sm:pl-2 sm:pr-2">
            <div className="flex-1 font-manrope text-lg text-center mt-4">
              {this.state.schedule.greeting}
            </div>
          </div>
        )}

        {this.state.schedule.avatarImage && (
          <div className="flex justify-center ">
            <img
              src={`${process.env.GATSBY_IMAGE_CDN}/${this.state.schedule.avatarImage}`}
              className="flex justify-center rounded-full"
              style={{ width: '100px' }}
            />
          </div>
        )}
        {/* {this.getTimeMatrixDisplay()} */}
      </>
    )
  }
  TimeFrameForm = () => {
    return (
      <div className="flex justify-center items-center  lg:w-5/6 border-2 border-neutral_1 rounded-sm shadow-lg ">
        <div>
          {/* selected item interface for group booking */}
          {this.state.group != null && !this.state.moreTimes && (
            <>
              <div className="mt-8 mb-8 text-lg">
                {this.state.schedule.ownerName} has invited you to help schedule
                a meeting.
              </div>
              {this.state.group && (
                <div className="mt-8 mb-8 p-4 ">
                  <div className="text-lg font-manrope mb-2">
                    {this.state.group.subject}
                  </div>
                  <div className="text-lg font-sarabun">
                    {this.state.group.body}
                  </div>
                </div>
              )}
              {this.props.groupmemberid == 'g' ? (
                <div className="mb-20">
                  <center>
                    <div className="font-sarabun">
                      Enter your email address to continue:
                    </div>
                    <CBInput
                      value={this.state.groupEmail}
                      hint="Your email address"
                      error={this.state.groupError}
                      onChange={(e) => {
                        this.setState({ groupEmail: e.toLowerCase() })
                      }}
                    >
                      Your email address
                    </CBInput>
                    <CBButton
                      loading={this.state.submitting}
                      onClick={(e) => {
                        if (validateEmail(this.state.groupEmail)) {
                          var attendee = this.state.group.attendees.filter(
                            (obj) => {
                              return (
                                Object.values(obj)[0].email ==
                                this.state.groupEmail
                              )
                            }
                          )
                          if (attendee.length > 0) {
                            window.location =
                              '/book/' +
                              this.state.group.parentId.replace('/__me__', '') +
                              '/' +
                              this.props.groupid +
                              '/' +
                              Object.keys(attendee[0])[0]
                          } else {
                            //add them
                            this.addUserToGroup(this.state.groupEmail)
                            // this.setState({
                            //   groupError:
                            //     "Sorry, we don't see that e-mail address on this meeting",
                            // })
                          }
                        } else {
                          this.setState({
                            groupError: 'Invalid e-mail address',
                          })
                        }
                      }}
                    >
                      Submit
                    </CBButton>
                  </center>
                </div>
              ) : (
                <>
                  <div>Select times</div>
                  {/* <CBList border={false}> */}
                  {this.state.vote_on_items.map((item) => {
                    return this.FreeButton(item.event, true)
                  })}
                  {/* </CBList> */}
                  {(this.state.declineMe ||
                    (this.state.selected_items != null &&
                      Object.keys(this.state.selected_items).length >= 1)) && (
                    <SubmitButton
                      style={{ marginBottom: 16 }}
                      onClick={(e) => {
                        this.submitChoices(true)
                      }}
                    >
                      {this.state.declineMe &&
                      Object.keys(this.state.selected_items).length == 0 ? (
                        'Decline Invite'
                      ) : (
                        <>
                          Submit Time
                          {Object.keys(this.state.selected_items).length > 1
                            ? 's'
                            : ''}
                        </>
                      )}
                    </SubmitButton>
                  )}
                  {!this.state.declineMe &&
                    (this.state.selected_items == null ||
                      Object.keys(this.state.selected_items).length == 0) && (
                      <div className="mb-12">
                        <LongButton
                          type="list"
                          icon={
                            <img src={Carat} style={{ float: 'right' }}></img>
                          }
                          onClick={(e) => {
                            // this.submitChoices(true)
                            // console.log("HAHAHA")
                            // this.submitChoices(true)
                            this.setState({
                              declineMe: true,
                            })
                          }}
                        >
                          These times don't work for me
                        </LongButton>
                      </div>
                    )}
                </>
              )}

              {/* {this.state.accounts.length == 0 && (
                  
                    <GroupAuth
                      accounts={this.state.accounts}
                      id={this.props.id}
                      groupid={this.props.groupid}
                      groupmemberid={this.props.groupmemberid}
                    ></GroupAuth>
                 
                )} */}
              {/* {this.state.accounts.length > 0 && (
                  <div >
                    <div className="flex p-4 flex-col bg-neutral_1 mt-8 mb-8">
                      <h4>Select specific calendars to auto-book:</h4>

                      <Select
                        placeholder="Select Calendar"
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                        style={{ width: '100%', margin: '0px 0px 0px 0px' }}
                        autoFocus
                        placeholder="Select Calendar"
                        value={[]}
                        onChange={(v) => 
                          {
                            
                           

                          if(this.state.bookerCalendarsSelected.includes(v[0])) {
                            //?
                        } else {
                          this.state.bookerCalendarsSelected.push(v[0])
                          this.selectSourceCalendar(this.state.bookerCalendarsSelected)
                        }
                           
                          }
                        } 
                      >
                        {this.state.allCalendars.map((c, i) => (
                          <Select.Option key={i} style={{ marginBottom: 0 }}>
                            <strong>{c.name || c.summary}</strong>{' '}
                            {
                              this.state.accounts.filter(
                                (a) =>
                                  a.calendarAccountId === c.calendarAccountId
                              )[0].email
                            }
                          </Select.Option>
                        ))}
                      </Select>
                      
                      <CBList borderLight={true}>
                        
                        {this.state.bookerCalendarsSelected.map((g) => {
                          let calendar = this.state.allCalendars[g]
                          return (
                            <CBListItem
                              key={calendar.name || calendar.summary}
                              borderLight={true}
                              onClick={(e) => {
                                // var a = guests.filter(e=>e != g)
                                // setGuests(a)
                                var filter = this.state.bookerCalendarsSelected.filter(q => q != g)
                                this.selectSourceCalendar(filter)
                              }}
                              icon={
                                <img
                                  src={Trash}
                                  style={{ float: 'right' }}
                                ></img>
                              }
                            > 
                              {calendar.name || calendar.summary} (
                              {
                                this.state.accounts.filter(
                                  (a) =>
                                    a.calendarAccountId === calendar.calendarAccountId
                                )[0].email
                              }
                              )
                            </CBListItem>
                          )
                        })}
                      </CBList>
                      <center>
                        <Button
                          disabled={
                            !(this.state.bookerCalendarsSelected.length > 0)
                          }
                          onClick={(e) => {
                            this.submitChoices(false)
                          }}
                        >
                          Submit Calendars For Auto-Booking
                        </Button>
                     </center>
                    </div>
                  </div>
                )} */}
            </>
          )}
        </div>
        {(this.props.groupid == null || this.state.moreTimes) && (
          <>
            {this.AttendeesForm()}

            <div className="flex justify-center w-full">{this.DayForm()}</div>
          </>
        )}
      </div>
    )
  }
  AttendeesForm = () => {
    return (
      <>
        {this.state.guests && this.state.guests.length > 0 && (
          <div
            style={{
              margin: '0 auto',
              marginBottom: '20px',
            }}
          >
            <h4>Add attendees</h4>
            <Select
              size="large"
              placeholder="Add Attendees"
              showSearch
              mode="multiple"
              optionFilterProp="children"
              style={{ width: '100%', margin: '0px 0px 0px 0px' }}
              autoFocus
              // placeholder="Add Attendees"
              defaultValue={''}
              value={this.state.guestSelected}
              onChange={(v) => this.selectAttendee(v)}
            >
              {this.state.guests.map((c, i) => (
                <Select.Option key={i}>
                  <strong>{c.name}</strong>
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      </>
    )
  }
  getTimeMatrixDisplay() {
    // return "FUCL"
    const {
      startTimeSun,
      endTimeSun,
      startTimeMon,
      endTimeMon,
      startTimeTue,
      endTimeTue,
      startTimeWed,
      endTimeWed,
      startTimeThu,
      endTimeThu,
      startTimeFri,
      endTimeFri,
      startTimeSat,
      endTimeSat,
    } = this.state.schedule
    var timeMatrix = {
      Mon: {
        start: startTimeMon,
        end: endTimeMon,
      },
      Tue: {
        start: startTimeTue,
        end: endTimeTue,
      },
      Wed: {
        start: startTimeWed,
        end: endTimeWed,
      },
      Thu: {
        start: startTimeThu,
        end: endTimeThu,
      },
      Fri: {
        start: startTimeFri,
        end: endTimeFri,
      },
      Sat: {
        start: startTimeSat,
        end: endTimeSat,
      },
      Sun: {
        start: startTimeSun,
        end: endTimeSun,
      },
    }

    return this.state.schedule.days.map((d) => (
      <div>
        {d} {timeMatrix[d].start.format('h:mm a')} -{' '}
        {timeMatrix[d].end.format('h:mm a')}
      </div>
    ))
  }
  DayForm = () => {
    const options = moment.tz.names().map((tz) => (
      <Select.Option key={tz}>
        {tz} ({moment.tz(this.state.currentDate, tz).format('Z z')})
      </Select.Option>
    ))
    const incLabel = this.state.schedule.meetingIncrement.map((e) => `${e} Min`)
    return (
      <div
        className="w-full flex flex-col lg:flex-row"
        style={{ minHeight: 700 }}
      >
        <div className="lg:w-1/3 lg:p-8 p-4 w-full">
          <div className="font-manrope text-lg text-center">
            Select a Duration and Date
          </div>
          {this.props.groupid == null && (
            <>
              <div className="flex-initial">
                <CBRadioGroup
                  title=""
                  selected={this.state.selectedIncrement}
                  labels={incLabel}
                  values={this.state.schedule.meetingIncrement}
                  onChange={(e) => {
                    this.changeMeetingIncrement(e)
                  }}
                ></CBRadioGroup>
              </div>
            </>
          )}
          {this.state.firstFreeDate && this.state.range && (
            <CBCalendar
              fullscreen={false}
              range={this.state.range}
              onPanelChange={(date) => {
                this.setState(
                  {
                    currentDate: date,
                    freeTimes: [],
                  },
                  () => {
                    const selMonth =
                      this.state.currentDate.month() +
                      this.state.currentDate.year() * 12
                    const currMonth = moment().month() + moment().year() * 12

                    this.loadSchedule(selMonth - currMonth)
                  }
                )
              }}
              excludedDays={Object.keys(this.state.excludedDays)}
              passDate={this.state.currentDate}
              onSelect={(date) => {
                this.setState({
                  currentDate: date,
                })
              }}
            />
          )}
        </div>
        <div className="w-full lg:w-1/3 mt-4 lg:mt-12  mb-4 lg:mb-12 lg:border-r-2 border-neutral_1 p-4 lg:pr-6">
          {this.TimeForm()}
        </div>
        <div className="lg:w-1/3 flex flex-col w-full border-0 rounded-sm pl-4 pt-4 pr-4 pb-4 lg:pl-6 lg:pt-6 lg:pb-6 lg:pr-1 border-neutral_1 order-first lg:order-last mt-1 lg:mt-10 ">
          {this.RenderGreeting()}
          {this.props.groupid != null &&
            (this.state.moreTimes || this.state.vote_on_items.length == 0) &&
            this.renderSelected()}

          <div className="lg:flex-grow"></div>
          {this.props.groupid == null && this.state.accounts.length == 0 && (
            <div className="flex-col justify-center mt-2 hidden lg:flex md:hidden ">
              <div>
                <center>
                  <h4>Filter times based on your calendar</h4>
                  <div className="flex gap-4 flex-row justify-center">
                    <a>
                      <GoogleLogo
                        width="28"
                        height="28"
                        onClick={(e) => this.doAuth('google')}
                      />
                    </a>
                    <a>
                      <FontAwesomeIcon
                        icon={faOutlook}
                        size="2x"
                        color={colorOutlook}
                        onClick={(e) => this.doAuth('ms')}
                      />
                    </a>
                  </div>
                </center>
              </div>
              <center>
                <small style={{ color: 'rgba(0,0,0,.65)' }}>
                  Already have a CalendarBridge account?{' '}
                  <a onClick={(e) => this.doAuth('cb')}>log in</a>
                </small>
              </center>
            </div>
          )}
          {this.props.groupid == null &&
            this.state.allCalendars.length > 0 &&
            this.state.loggedInUserID != this.state.schedule.ownerId && (
              <>
                {this.state.showBookerCalendarSelector && (
                  <div className="hidden lg:flex md:hidden flex-col bg-neutral_1 p-2 mb-2">
                    <h4>Select specific calendars to filter time slots: </h4>
                    <div className="flex-initial">
                      <Select
                        placeholder="Select Calendar"
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                        style={{ width: '100%', margin: '0px 0px 20px 0px' }}
                        autoFocus
                        // placeholder="Select Calendar"
                        value={[]}
                        // defaultValue={this.state.bookerCalendarsSelected}
                        onChange={(v) => {
                          if (
                            this.state.bookerCalendarsSelected.includes(v[0])
                          ) {
                            //?
                          } else {
                            this.state.bookerCalendarsSelected.push(v[0])
                            this.selectSourceCalendar(
                              this.state.bookerCalendarsSelected
                            )
                          }
                        }}
                      >
                        {this.state.allCalendars.map((c, i) => (
                          <Select.Option key={i} style={{ marginBottom: 0 }}>
                            <strong>{c.name || c.summary}</strong>{' '}
                            {
                              this.state.accounts.filter(
                                (a) =>
                                  a.calendarAccountId === c.calendarAccountId
                              )[0].email
                            }
                          </Select.Option>
                        ))}
                      </Select>

                      <CBList borderLight={true}>
                        {this.state.bookerCalendarsSelected.map((g) => {
                          let calendar = this.state.allCalendars[g]
                          return (
                            <CBListItem
                              key={calendar.name || calendar.summary}
                              borderLight={true}
                              onClick={(e) => {
                                var filter =
                                  this.state.bookerCalendarsSelected.filter(
                                    (q) => q != g
                                  )
                                this.selectSourceCalendar(filter)
                              }}
                              icon={
                                <img
                                  src={Trash}
                                  style={{ float: 'right' }}
                                ></img>
                              }
                            >
                              <div className="text-sm">
                                {calendar.name || calendar.summary} (
                                {
                                  this.state.accounts.filter(
                                    (a) =>
                                      a.calendarAccountId ===
                                      calendar.calendarAccountId
                                  )[0].email
                                }
                                ){' '}
                              </div>
                            </CBListItem>
                          )
                        })}
                      </CBList>
                    </div>
                    Signed in
                  </div>
                )}
                {this.state.bookerCalendarsSelected &&
                  this.state.bookerCalendarsSelected.length > 0 && (
                    <Alert
                      message={
                        <>
                          Times filtered based on your calendar
                          {this.state.bookerCalendarsSelected.length != 1
                            ? 's'
                            : ''}
                          . <br />
                          {/* <a onClick={(e) => this.toggleShowBookerCalendarSelector()}>
                    Change Calendars
                  </a> */}
                        </>
                      }
                      type="info"
                      style={{ marginBottom: 10 }}
                      showIcon
                    />
                  )}
              </>
            )}
        </div>
      </div>
    )
  }

  getTimeFormat = () => {
    if (this.state.schedule.militaryTime) {
      return 'H:mm'
    } else {
      return 'h:mm a'
    }
  }

  getDateFormat = () => {
    if (this.state.schedule.militaryTime) {
      return ' (DD/MM)'
    } else {
      return ' (MM/DD)'
    }
  }

  getVoteFormat = () => {
    return 'dddd, MMM DD ' + this.getTimeFormat()
  }

  FreeButton = (event, voteOn = false) => {
    var timeFormat = this.getTimeFormat()

    var dateFormat = this.getDateFormat()
    var voteFormat = this.getVoteFormat()
    var current = this.state.currentDate.tz(this.state.timezone) //cache me
    var eventStart = momentTz(event.start).tz(this.state.timezone)
    var eventEnd = momentTz(event.end).tz(this.state.timezone)
    var diffDate =
      current.dayOfYear() != eventStart.dayOfYear() ||
      current.dayOfYear() != eventEnd.dayOfYear()

    var passed = eventStart.isBefore(momentTz())

    var deaded = event.disabled

    const normal =
      'font-sarabun text-center pt-2 pb-2 cursor-pointer border rounded-sm border-neutral_2'
    const group = `flex font-sarabun pl-4 pr-2 pt-2 pb-2 cursor-pointer border-2 rounded-sm border-neutral_1`
    if (this.state.debug != 'false' && event.subject != '') {
      return (
        <div
          key={event.start + event.end + event.subject}
          className={`flex mb-2 font-sarabun pl-4 pr-2 pt-2 pb-2 flex flex-col border-2 rounded-sm ${
            event.status == 'free' ? 'border-indigo-400' : 'border-red-500'
          }`}
        >
          <div>
            {event.subject != '' && <div>Event Subject: {event.subject}</div>}
          </div>
          <div>
            {!event.allDay &&
              `${eventStart.format(timeFormat)}
          - ${eventEnd.format(timeFormat)}`}
          </div>
          <div>{event.allDay && <div> All Day </div>}</div>
          <div>Status: {event.status}</div>
        </div>
      )
    }
    if (passed) {
      return (
        <div
          key={event.start}
          className={
            'flex mb-2 font-sarabun pl-4 pr-2 pt-2 pb-2 border-2 rounded-sm border-neutral_1'
          }
        >
          {!diffDate && !voteOn
            ? `${eventStart.format(timeFormat)}
          - ${eventEnd.format(timeFormat)}`
            : voteOn
            ? `${eventStart.format(voteFormat)}
            - ${eventEnd.format(timeFormat + ' z ')}`
            : `${eventStart.format(timeFormat)}
          - ${eventEnd.format(timeFormat + dateFormat)}`}{' '}
          - <div className="ml-2 mt-1 text-xs text-red-600">expired</div>
        </div>
      )
    } else if (deaded) {
      return (
        <div
          key={event.start}
          className={
            'flex mb-2 font-sarabun pl-4 pr-2 pt-2 pb-2 border-2 rounded-sm border-neutral_1'
          }
        >
          {!diffDate && !voteOn
            ? `${eventStart.format(timeFormat)}
          - ${eventEnd.format(timeFormat)}`
            : voteOn
            ? `${eventStart.format(voteFormat)}
            - ${eventEnd.format(timeFormat + ' z ')}`
            : `${eventStart.format(timeFormat)}
          - ${eventEnd.format(timeFormat + dateFormat)}`}{' '}
          -{' '}
          <div className="ml-2 mt-1 text-xs text-red-600">
            No Longer available
          </div>
        </div>
      )
    }
    return (
      <div className="mb-2 w-full" key={event.start}>
        <div
          className={this.props.groupid == null ? normal : group}
          // long={true}
          type="ternary"
          style={{ marginBottom: 10 }}
          label={
            <>
              {this.state.already_selected_items &&
                this.state.already_selected_items[event.start] && (
                  <>
                    {' '}
                    ({this.state.already_selected_items[event.start]} Vote
                    {this.state.already_selected_items[event.start] == 1
                      ? ''
                      : 's'}
                    )
                  </>
                )}
            </>
          }
          onClick={(e) => {
            if (this.props.groupid == null) {
              this.changeBookingValue({
                startTime: new Date(event.start),
                endTime: new Date(event.end),
              })

              this.nextPage()
              window.scrollTo(0, 0)
            } else {
              if (this.state.selected_items[event.start] == null) {
                var item = {
                  key: event.start,
                  event: event,
                  start: event.start,
                  end: event.end,
                }
                // only allow 100 selections
                if (Object.values(this.state.selected_items).length < 100) {
                  var things = this.state.selected_items

                  things[event.start] = item

                  var already = this.state.already_selected_items
                  already[event.start] = already[event.start]
                    ? already[event.start] + 1
                    : 1
                  this.setState(
                    {
                      selected_items: { ...things },
                      already_selected_items: { ...already },
                      declineMe: false,
                    },
                    () => {
                      console.log(this.state.selected_items)
                    }
                  )
                }
              } else {
                //remove from list
                var things = this.state.selected_items
                var already = this.state.already_selected_items
                already[event.start] = already[event.start]
                  ? already[event.start] - 1
                  : null
                if (already[event.start] == 0) {
                  delete already[event.start]
                }
                delete things[event.start]
                this.setState({
                  selected_items: { ...things },
                  already_selected_items: { ...already },
                })
              }
            }
          }}
        >
          <div>
            {!diffDate && !voteOn
              ? `${eventStart.format(timeFormat)}
          - ${eventEnd.format(timeFormat)}`
              : voteOn
              ? `${eventStart.format(voteFormat)}
              - ${eventEnd.format(timeFormat + ' z ')}`
              : `${eventStart.format(timeFormat)}
          - ${eventEnd.format(timeFormat + dateFormat)}`}
          </div>
          {this.props.groupid != null && (
            <>
              <div className="flex flex-grow items-end justify-end">
                {this.state.already_selected_items &&
                  this.state.already_selected_items[event.start] &&
                  this.state.already_selected_items[event.start] - 1 > 0 && (
                    <div className="pr-4">
                      {' '}
                      ({this.state.already_selected_items[event.start] - 1} Vote
                      {this.state.already_selected_items[event.start] - 1 == 1
                        ? ''
                        : 's'}
                      )
                    </div>
                  )}
                <Radio checked={this.state.selected_items[event.start]}></Radio>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  TimeForm = () => {
    const current = momentTz(this.state.currentDate).tz(this.state.timezone)

    const times = this.state.freeTimes[current.format('YYYY-MM-DD')]
      ? this.state.freeTimes[current.format('YYYY-MM-DD')]
      : []

    let debug =
      this.state.sourceItems &&
      this.state.sourceItems[current.format('YYYY-MM-DD')]
        ? []
            .concat(this.state.sourceItems[current.format('YYYY-MM-DD')])
            .concat(times)
            .sort((a, b) => {
              if (a.allDay) {
                return -1
              } else return a.start.localeCompare(b.start)
            })
        : times

    const options = moment.tz.names().map((tz) => (
      <Select.Option key={tz}>
        {tz} ({moment.tz(this.state.currentDate, tz).format('Z z')})
      </Select.Option>
    ))

    return (
      <div className="border-0 border-neutral_1 p-4">
        {/* {this.props.groupid == null && <h4>3. Pick a time:</h4>}
        {this.props.groupid != null && <h4>3. Pick open times:</h4>} */}

        <div className="flex justify-center font-bold mb-4 mt-4">
          {current.format('dddd, MMMM Do')}
        </div>
        <div className="flex flex-col w-full">
          {this.state.debug != 'false' ? (
            <div>DEBUG {debug.map((e) => this.FreeButton(e))}</div>
          ) : (
            times.map((e) => this.FreeButton(e))
          )}

          <div>
            {times.length === 0 && (
              <div className="p-4 padding bg-neutral_0 border text-center mb-4">
                {this.state.loading ? 'Loading schedule' : 'No Times Available.'}
                {this.state.guestSelected &&
                  this.state.guests &&
                  this.state.guests.length > 0 &&
                  this.state.guestSelected.length == 0 && (
                    <small>Please add an attendee</small>
                  )}
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-center">
          <Select
            showSearch
            suffixIcon={<FontAwesomeIcon icon={faHistory} />}
            size="small"
            style={{ width: 250, border: 0 }}
            placeholder="Select a timezone"
            filterOption={(input, option) => {
              return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            value={this.state.timezone}
            onChange={this.changeTimezone}
          >
            {options}
          </Select>
        </div>
        {/* 
          
          {Object.keys(this.state.already_selected_items).length > 0 && <Col>{times.filter((e)=>{
            var test = this.state.already_selected_items[e.start]
            
            return test != null
          }
          ).map((e) => this.FreeButton(e))}</Col>} */}
      </div>
    )
  }

  CTA = () => {
    return (
      <div className="flex flex-col justify-center font-sarabun">
        <center>
          <h3 style={{ marginBottom: '10px', fontSize: '1.2em' }}>
            Sync all your Calendars. Schedule with Ease.
          </h3>
        </center>
        <div className="flex flex-row rounded-lg mt-4 border-2 gap-8 mb-4 border-neutral_1 shadow-sm p-4 justify-center center-items">
          <div className="pl-4">
            <ul className="list-disc font-sarabun pt-2">
              <li>Sync all of your calendars</li>
              <li>Create scheduling links</li>
              <li>Schedule meetings with large groups</li>
            </ul>
          </div>
          <div>
            <CtaForm1
              autofocus
              size="default"
              email={this.state.booking.email}
              referral={this.state.schedule.id}
            />
          </div>
        </div>
      </div>
    )
  }

  ScheduleCompleteScreen = () => {
    const authed = this.state.authed
    const email = this.state.booking.email
    const noInvite =
      this.state.declineMe || this.state.selected_items.length == 0

    const outOfChoices = this.state.vote_on_items == null
    return (
      <div
        style={{ minHeight: 700 }}
        className="border-2 border-neutral_1 lg:pt-32 shadow-lg lg:w-2/3  pb-8 flex flex-col "
      >
        <div className="flex flex-col items-center w-full">
          <div
            className="text-xl font-manrope"
            level={3}
            style={{ marginTop: 0, marginBottom: 20 }}
          >
            {this.props.groupid == null
              ? 'Your meeting is scheduled. We sent a calendar invite to all attendees.'
              : noInvite != null
              ? !outOfChoices
                ? 'Your choice has been submitted to the organizer'
                : 'Group meeting invite has expired.'
              : 'Your time choices have been submitted, you will receive a calendar invite soon!'}
          </div>
          <Result status="success"></Result>

          <div className="flex flex-col justify-center">
            {/* {!authed && (
              <div className="flex flex-col justify-center">
                {this.CTA(email)}
                {email && (
                  <div>
                    <center>
                      <div style={{ marginBottom: '10px', fontSize: '1.2em' }}>
                        or
                      </div>
                      <Button
                        onClick={(e) => {
                          localStorage.clear()
                          window.location = '/'
                        }}
                      >
                        Forget Me, Please!
                      </Button>
                    </center>
                  </div>
                )}
              </div>
            )} */}
          </div>
        </div>
      </div>
    )
  }

  DynamicSeo = () => {
    return this.state.schedule.iconImage && this.state.schedule.ownerName ? (
      <SEO
        title={`Find a time to meet ${this.state.schedule.ownerName}`}
        image={`${process.env.GATSBY_IMAGE_CDN}/${this.state.schedule.iconImage}`}
      />
    ) : (
      <SEO title={`Find a time to meet`} />
    )
  }

  EventDetailForm = () => {
    return (
      <div className="flex animate-fade-in flex-col justify-center items-center border-2 w-full lg:w-5/6 shadow-lg border-neutral_1 p-2 lg:p-8 min-w-0">
        <div
          className="font-manrope mt-2 lg:mt-0 lg:text-2xl"
          style={{ textAlign: 'center' }}
        >
          Meeting Details
          <br />
        </div>

        <div
          className="font-manrope lg:text-2xl text-neutral_4"
          style={{ textAlign: 'center', marginTop: 20 }}
        >
          {momentTz(this.state.booking.startTime)
            .tz(this.state.timezone)
            .format('LLLL z')}
        </div>
        {process.env.GATSBY_DEBUG_UI == 'true' &&
        this.state.schedule.tz != this.state.timezone ? (
          <h5 style={{ textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
            ({this.state.schedule.ownerName} time:{' '}
            {momentTz(this.state.booking.startTime)
              .tz(this.state.schedule.tz)
              .format('LLLL z')}
            )
          </h5>
        ) : (
          <></>
        )}

        {/* <Form.Item
            
            layout="vertical"
            validateStatus={
              this.state.bookingValidation.name ? undefined : 'error'
            }
            help={!this.state.bookingValidation.name && 'Please enter a name.'}
          > */}
        <div className="lg:w-1/2 w-full">
          <CBInput
            label="Name"
            hint=""
            error={!this.state.bookingValidation.name && 'Please enter a name.'}
            value={this.state.booking.name}
            onChange={(e) => {
              this.changeBookingValue({
                name: e,
              })
            }}
          />
          {/* </Form.Item> */}
          {/* <Form.Item
            layout="vertical"
            validateStatus={
              this.state.bookingValidation.email ? undefined : 'error'
            }
            help={
              !this.state.bookingValidation.email &&
              'Please enter a valid email.'
            }
          > */}
          <CBInput
            error={
              !this.state.bookingValidation.email &&
              'Please enter a valid email.'
            }
            label="Email"
            hint=""
            type="email"
            value={this.state.booking.email}
            onChange={(e) => {
              this.changeBookingValue({
                email: e,
              })
            }}
          />
          {/* </Form.Item> */}
          {/* <Form.Item label="Other Attendee Emails (optional)" layout="vertical"> */}
          {!this.state.addGuests ? (
            <div className="mt-4">
              <CBButton
                type="circular"
                onClick={(e) => this.setState({ addGuests: true })}
              >
                + Add Guests
              </CBButton>
            </div>
          ) : (
            <>
              <div className="font-sarabun text-neutral_4 mt-4 mb-2">
                Additional Attendee Emails{' '}
                <a onClick={(e) => this.setState({ addGuests: false })}>
                  {' '}
                  - hide
                </a>
              </div>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                tokenSeparators={[',', ' ']}
                placeholder=""
                open={false}
                value={this.state.booking.guests}
                onChange={(value) => {
                  const filteredGuests = value.filter(
                    (guest) => guest != '' && validateEmail(guest)
                  )
                  const difference = value.filter(
                    (x) => !filteredGuests.includes(x)
                  )
                  if (difference.length > 0) {
                    message.error('Invalid email(s): ' + difference.join(', '))
                  }
                  this.changeBookingValue({
                    guests: filteredGuests,
                  })
                }}
              />
            </>
          )}
          {/* </Form.Item> */}
          {/* <Form.Item
            
            layout="vertical"
            validateStatus={
              this.state.bookingValidation.subject ? undefined : 'error'
            }
            help={
              !this.state.bookingValidation.subject && 'Please enter a subject.'
            }
          > */}
          {this.state.schedule.meeting &&
            this.state.schedule.integrations.length > 0 && (
              <div className="mt-4 mb-4">
                <div className="font-sarabun text-gray-900">
                  Conference type:
                </div>
                <div className="flex gap-x-4 mt-4">
                  <div
                    className="flex cursor-pointer p-2 pr-4 border-2 rounded-full gap-x-2"
                    onClick={(e) => {
                      this.changeBookingValue({
                        meetingType: 'zoom',
                      })
                    }}
                  >
                    {this.state.booking.meetingType == 'zoom' ? (
                      <SelectOn></SelectOn>
                    ) : (
                      <SelectOff></SelectOff>
                    )}{' '}
                    Zoom
                  </div>
                  <div
                    className="flex p-2 pr-4 cursor-pointer  border-2 rounded-full gap-x-2"
                    onClick={(e) => {
                      this.changeBookingValue({
                        meetingType: this.state.schedule.type,
                      })
                    }}
                  >
                    {this.state.booking.meetingType ==
                    this.state.schedule.type ? (
                      <SelectOn></SelectOn>
                    ) : (
                      <SelectOff></SelectOff>
                    )}{' '}
                    {this.state.schedule.type == 'google'
                      ? 'Google Meet'
                      : 'Microsoft Teams'}
                  </div>
                </div>
              </div>
            )}
          {this.state.schedule.customSubject.includes('{subject}') && (
            <CBInput
              label="Subject"
              hint="Describe the purpose of the meeting"
              value={this.state.booking.subject}
              error={
                !this.state.bookingValidation.subject &&
                'Please enter a subject.'
              }
              onChange={(e) => {
                this.changeBookingValue({
                  subject: e,
                })
              }}
            />
          )}
          {/* </Form.Item> */}
          {this.state.schedule.questions != null &&
            this.state.schedule.questions.length > 0 && (
              <>
                {this.state.schedule.questions.map((e, i) => (
                  <>
                    {/* <Form.Item label={e} layout="vertical"> */}
                    <CBTextArea
                      label={e}
                      rows="1"
                      hint={``}
                      value={this.state.questions[i]}
                      onChange={(e) => {
                        var qs = this.state.questions
                        qs[`${i}`] = e
                        this.setState(
                          {
                            questions: qs,
                          },
                          () => {
                            console.log(this.state.questions)
                          }
                        )
                      }}
                    />
                    {/* </Form.Item> */}
                  </>
                ))}
              </>
            )}
          {/* <Form.Item
            label={
              this.state.schedule.questions == null ||
              this.state.schedule.questions.length == 0
                ? 'Description'
                : 'Additional Information'
            }
            layout="vertical"
          > */}
          {!!(
            this.state.schedule.questions == null ||
            this.state.schedule.questions.length == 0
          ) && (
            <CBTextArea
              label="Description"
              value={this.state.booking.body}
              style={{ height: 75 }}
              hint="Any other details you would like to provide"
              onChange={(e) => {
                this.changeBookingValue({
                  body: e,
                })
              }}
            />
          )}
          {/* </Form.Item> */}

          {this.ButtonBar()}
        </div>
        
      </div>
    )
  }

  ButtonBar = () => (
    <div className="flex flex-row gap-x-4 justify-end items-center">
      {this.state.page !== 0 && (
        <CBButton
          type="ternary"
          size="large"
          block
          onClick={this.prevPage}
          style={{ maxWidth: 100, marginRight: 10 }}
        >
          <FontAwesomeIcon
            icon={<LegacyIcon type={faArrowLeft} />}
            style={{ marginRight: 10 }}
          />
          Back
        </CBButton>
      )}
      <CBButton
        type="primary"
        size="large"
        onClick={(e) =>
          this.state.page === 1 ? this.submitBooking(e) : this.nextPage
        }
        block
        style={{ maxWidth: this.state.page === 0 ? 360 : 210 }}
        loading={this.state.submitting}
        htmlType={this.state.page === 1 ? 'submit' : 'button'}
        disabled={this.state.page === 0 && !this.state.booking.startTime}
      >
        {this.state.page === this.state.lastPage ? 'Book Meeting' : 'CONTINUE'}
      </CBButton>
    </div>
  )

  render() {
    const pages = [
      this.TimeFrameForm,
      this.EventDetailForm,
      this.ScheduleCompleteScreen,
    ]
    if (this.state.error) {
      if (this.state.error == 'cancelled') {
        return (
          <div style={{ margin: '0 auto', padding: '20px 20px' }}>
            <div style={{ textAlign: 'center', marginBottom: 8 }}>
              <center>
                <a href="https://calendarbridge.com">
                  <LogoMono height="50" width="200" />
                </a>
              </center>
            </div>
            <div className="lg:m-4 lg:p-4 lg:border-2 border-neutral_1 shadow-lg min-h-screen">
              <Result
                title="Meeting was cancelled by organizer"
                extra={<div></div>}
              />
            </div>
          </div>
        )
      } else {
        return (
          <div style={{ margin: '0 auto', padding: '20px 20px' }}>
            <div style={{ textAlign: 'center', marginBottom: 8 }}>
              <center>
                <a href="https://calendarbridge.com">
                  <LogoMono height="50" width="200" />
                </a>
              </center>
            </div>
            <div className="lg:m-4 lg:p-4 lg:border-2 border-neutral_1 shadow-lg min-h-screen">
              <Result
                title="There is an issue with this booking page, please contact the page’s owner directly or try again later."
                extra={
                  <div>
                    <small>Is this your booking page? </small>
                    <a
                      onClick={(e) =>
                        redirectToService(window.location.origin + '/login')
                      }
                      type="primary"
                      key="console"
                    >
                      Log In
                    </a>
                  </div>
                }
              />
            </div>
          </div>
        )
      }
    } else if (!this.state.schedule) {
      return (
        <div
          style={{ textAlign: 'center', paddingTop: 100, minWidth: '380px' }}
        >
          <Spin
            indicator={
              <Loading3QuartersOutlined spin style={{ fontSize: '100px' }} />
            }
          />
        </div>
      )
    }

    return (
      <div className="flex justify-center flex-col bg-white mt-12">
        {this.DynamicSeo()}
        <span className="flex justify-center mb-4">
          {this.state.schedule.iconImage ? (
            <>
              {this.state.schedule.iconImage.includes('@2x') ? (
                <img
                  src={`${process.env.GATSBY_IMAGE_CDN}/${this.state.schedule.iconImage}`}
                  srcset={`${process.env.GATSBY_IMAGE_CDN}/${this.state.schedule.iconImage} 2x`}
                  className="flex justify-center lg:max-w-xl"
                />
              ) : (
                <img
                  src={`${process.env.GATSBY_IMAGE_CDN}/${this.state.schedule.iconImage}`}
                  className="flex justify-center lg:max-w-xl"
                />
              )}
            </>
          ) : (
            <a href="https://calendarbridge.com?ref=booking-page" target="_blank">
            <LogoMono
              className="flex justify-center"
              height="50"
              width="200"
              alt="CalendarBridge"
            /></a>
          )}
        </span>
        <div className="flex lg:p-4 justify-center">
          {pages[this.state.page]()}
        </div>
        <div className="flex flex-row  justify-center items-center">
          <a 
          href="https://calendarbridge.com/terms-and-privacy/?ref=booking-page"
          target="_blank"
          style={{
              color: '#145BAB',
              cursor: 'pointer',
              width: '126px',
              textAlign: 'center',
            }}
            
          >
            Privacy policy
          </a>
        </div>
      </div>
    )
  }
}

export default ScheduleBookingPage
