import React, { useEffect, useState } from 'react'
import Title from 'antd/lib/typography/Title'
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Row, Col, Card, Button, Result, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faOutlook } from '../../../utils/customIcons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { colorOutlook, colorGoogle, colorExchange } from '../../../utils/colors'
import QueryString from 'query-string'
import { API, Auth } from 'aws-amplify'
import { navigate } from 'gatsby'
import {Cookies} from 'js-cookie'
import { useGlobalState } from '../../../store'
import { loadAccounts } from '../../../utils/fetchData'



const ScheduleBookingAuthPage = () => {
  const [showList, setShowList] = useState(false)
  const [callbackStatus, setCallbackStatus] = useState('loading')
  const [errorMessage, setErrorMessage] = useState(null)
  const [, setAccounts] = useGlobalState('accounts')
  const [, setLoadingCoreData] = useGlobalState('loadingCoreData')

  useEffect(() => {
    let params = QueryString.parse(window.location.search)

    if (params.code) {
      if(params.scope && params.scope.includes("google")){

        codeCallbackAccountCreation(params.code, "google", params.state,params.scope)
      } else {

        codeCallbackAccountCreation(params.code, "ms", params.state,params.scope)
      }
    } else {
      setErrorMessage('/book/' + params.state)
    }
  }, [])

  const codeCallbackAccountCreation = async (code, type, state,scope) => {
    
    try{
    var auth = await API.post('group-bookings', '/group-bookings?auth=true', {
      body: {
        code,
        type,
        scope,
        state,
        redirect:
          type === 'ms'
            ? window.location.origin + process.env.GATSBY_OAUTH_O365_URL_BOOK
            : window.location.origin + process.env.GATSBY_OAUTH_GOOGLE_URL_BOOK,
      },
    })
    //console.log(auth)
    localStorage.setItem("cal_auth_email",auth.email)
    localStorage.setItem("cal_auth_account",auth.account.calendarAccountId)
    localStorage.setItem("cal_auth_account_type",auth.account.calendarAccountType)
    if(state.includes("external")) {
      var redirect = auth.account.displayName
      // auth.account.displayName !="" // redirect to that which will be the internal app url
      if(redirect.startsWith("cb_web")){
        console.log("REDIRECT EXTERNAL IFRAME")
        localStorage.setItem("scheduler_id",auth.account.errorState)
        window.location.href = window.location.origin + '/external/edit/?schedulerId=' + auth.account.errorState + "&session=" + redirect.split(":")[1] // +edit token?
      } else {
        console.log("REDIRECT EXTERNAL APP")
        window.location.href = redirect + "?schedulerId=" + auth.account.errorState  // redirect for mobile app
      }
      
    } else
    if(state.includes("@")) {
      window.location.href = window.location.origin + '/dashboard/group/new'
    } else {
    window.location.href =  state //window.location.origin + '/book/' +
  }
    } catch(error) {
      
      setErrorMessage('/book/' + state)
    }

  }

  function renderError() {
    return (
      <Result
        status="warning"
        title="Darn! We had trouble linking your account."
        subTitle={errorMessage}
        extra={
          <Button onClick={() => navigate(errorMessage)}>
            Try Again
          </Button>
        }
      />
    )
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {errorMessage && (renderError())}
      {!errorMessage && <Loading3QuartersOutlined spin style={{ padding: 100, fontSize: '100px' }} />}
  </div>
  );
  
}


export default ScheduleBookingAuthPage
