import React from 'react'
import Title from 'antd/lib/typography/Title'
import Logo from '../../../images/svg/new_CB_logo.svg'
import CtaForm1 from '../../ctaform1'
import { Row, Col, Button, Result } from 'antd'
import QueryString from 'query-string'
import SEO from '../../../components/SEO'

const ScheduleBookingCompletePage = () => {
  var email = localStorage.getItem('cal_auth_email')
  var emailFill = QueryString.parse(window.location.search)['emailFill']

  var group = QueryString.parse(window.location.search)['group']
  var authed = QueryString.parse(window.location.search)['authed'] != null

  var noInvite = QueryString.parse(window.location.search)['no_invite']
  return (
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <SEO title="Booking Complete" />
      <center className="mb-8">
        <a href="https://calendarbridge.com">
          <Logo height="50" width="200"></Logo>
        </a>
        <div
          style={{ minHeight: 700 }}
          className="border-2 border-neutral_1 lg:pt-32 shadow-lg lg:w-2/3 mt-8 pb-8 flex flex-col "
        >
          <div className="flex flex-col items-center w-full">
            <div
              className="text-xl font-manrope"
              level={3}
              style={{ marginTop: 0, marginBottom: 20 }}
            >
              {group == null
                ? 'Your meeting is scheduled. We sent a calendar invite to all attendees.'
                : noInvite != null ? 'Your choice has been submitted to the organizer' : 'Your time choices have been submitted, you will receive a calendar invite soon!'}
            </div>
            <Result status="success"></Result>
            {/* {!authed && (
              <div className="flex flex-col justify-center">
                
                  <h3 style={{ marginBottom: '10px', fontSize: '1.2em' }}>
                    Sync all your Calendars. Schedule with Ease.
                  </h3>
                  <CtaForm1
                    autofocus
                    size="default"
                    email={email || emailFill}
                  />
                  {email && (
                    <div>
                      <div style={{ marginBottom: '10px', fontSize: '1.2em' }}>
                        or
                      </div>
                      <Button
                        onClick={(e) => {
                          localStorage.clear()
                          window.location.reload()
                        }}
                      >
                        Forget Me, Please!
                      </Button>
                    </div>
                  )}
                
              </div>
            )} */}
          </div>
        </div>
      </center>
    </div>
  )
}

export default ScheduleBookingCompletePage
