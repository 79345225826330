import 'react-universal-hooks'
import React from 'react'
import { Router } from '@reach/router'
import Amplify from 'aws-amplify'

import { GlobalStateProvider } from '../store'
import apiConfig from '../utils/apiConfig'

import Layout from '../components/Layout'
import ScheduleBookingAuthPage from '../components/schedules/pages/ScheduleBookingAuthPage'
import ScheduleBookingCompletePage from '../components/schedules/pages/ScheduleBookingCompletePage'
import ScheduleBookingPage from '../components/schedules/pages/ScheduleBookingPage'

Amplify.configure(apiConfig)

export default class BookingPage extends React.Component {
  render = () => (
    <GlobalStateProvider>
      <Layout location={this.props.location}>
        <Router>

          <ScheduleBookingAuthPage path="/book/auth" />
          <ScheduleBookingCompletePage path="/book/complete" />

          <ScheduleBookingPage path="/book/:id/:alias/:groupid/:groupmemberid" />
          <ScheduleBookingPage path="/book/:id/:groupid/:groupmemberid" />
          <ScheduleBookingPage path="/book/:id/:alias" />
          <ScheduleBookingPage path="/book/:id" />
        </Router>
      </Layout>
    </GlobalStateProvider>
  )
}
