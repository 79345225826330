import { API } from 'aws-amplify'

const loadAllData = async user => {
  try {
    let response = await API.get('account', '/account/?email=' + encodeURIComponent(user.username))
    const accounts = response.items
    for (const [acctIdx, acct] of accounts.entries()) {
      for (const [calIdx, cal] of acct.calendars.entries()) {
        accounts[acctIdx].calendars[calIdx].calendarAccountId =
          acct.calendarAccountId
        accounts[acctIdx].calendars[calIdx].calendarAccountType =
          acct.calendarAccountType
          accounts[acctIdx].calendars[calIdx].email =
          acct.email
      }
    }
    
    return {
      accounts,
      syncs: response.bridges,
      schedules: response.schedules,
      integrations: response.integrations,
      license: response.license,
    }
  } catch (error) {
    return {
      error,
    }
  }
}

const loadAccounts = async () => {
  try {
    let response = await API.get('account', '/account/?calendars=true')
    const accounts = response.items
    for (const [acctIdx, acct] of accounts.entries()) {
      for (const [calIdx, cal] of acct.calendars.entries()) {
        accounts[acctIdx].calendars[calIdx].calendarAccountId =
          acct.calendarAccountId
        accounts[acctIdx].calendars[calIdx].calendarAccountType =
          acct.calendarAccountType
      }
    }
    return accounts
  } catch (error) {
    return []
  }
}

const loadSyncs = async () => {
  try {
    const response = await API.get('bridge', '/bridge/')
    return response.items
  } catch (error) {
    return []
  }
}

const loadSchedules = async () => {
  try {
    const response = await API.get('schedules', '/schedules')
    return response.sourceItems
  } catch (error) {
    return []
  }
}

export { loadAllData, loadAccounts, loadSchedules, loadSyncs }
