import React, { createContext, useReducer, useContext } from 'react'
import _ from 'lodash'

/* Define a context and a reducer for updating the context */
const GlobalStateContext = createContext()

const SET_ACCOUNTS = 'SET_ACCOUNTS'
const SET_SYNCS = 'SET_SYNCS'
const SET_SCHEDULES = 'SET_SCHEDULES'
const SET_INTEGRATIONS = 'SET_INTEGRATIONS'
const SET_USER = 'SET_USER'
const SET_LICENSE = 'SET_LICENSE'
const SET_ERRORS = 'SET_ERRORS'
const SET_LOADING_CORE_DATA = 'SET_LOADING_CORE_DATA'

const initialState = {
  accounts: [],
  syncs: [],
  schedules: [],
  integrations: [],
  user: {},
  license: {},
  loadingCoreData: true,
}

const actionToFieldMap = {
  [SET_ACCOUNTS]: 'accounts',
  [SET_SYNCS]: 'syncs',
  [SET_SCHEDULES]: 'schedules',
  [SET_USER]: 'user',
  [SET_LICENSE]: 'license',
  [SET_INTEGRATIONS]: 'integrations',
  [SET_ERRORS]: 'errors',
  [SET_LOADING_CORE_DATA]: 'loadingCoreData',
}
const fieldToActionMap = _.invert(actionToFieldMap)

const globalStateReducer = (state, action) => {
  if (action.type in actionToFieldMap) {
    return {
      ...state,
      [actionToFieldMap[action.type]]: action.payload,
    }
  }
  return state
}

/* Export a component to provide the context to its children. This is used in our _app.js file */

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState)

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  )
}

/*
Default export is a hook that provides a simple API for updating the global state.
This also allows us to keep all of this state logic in this one file
*/

export const useGlobalState = field => {
  const [state, dispatch] = useContext(GlobalStateContext)

  const setFunc = payload => {
    dispatch({
      type: fieldToActionMap[field],
      payload,
    })
  }

  return [state[field], setFunc]
}
